@keyframes up_down_line {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-8px);
  }
}
.rwd002-pagetop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -50px;
  width: 70px;
  height: 180px;
  font-size: $font-size-xs;
  font-family: "Roboto", $font-family-sans-serif;
  color: $body-color;
  text-decoration: none;
  letter-spacing: 0.1em;
  transform-origin: bottom;

  @include media-up(lg) {
    right: map-get($spacers, 3);
  }
  @include media-down(lg) {
    right: calc(50% - 35px);
    bottom: -70px;
    height: auto;
    img {
      height: auto;
      max-width: 13.333vw;
    }
  }

  &:hover,
  &:focus {
    text-decoration: none;
    img {
      animation: up_down_line 0.9s infinite ease-in-out;
    }
  }
  &__wrap {
    width: 100%;
    z-index: 10;
    transition: $transition-base;
    @include media-up(lg) {
      position: fixed;
      position: sticky;
      bottom: 80px;
      margin: 0 auto;
      padding-left: map-get($spacers, 1);
      padding-right: map-get($spacers, 1);
    }
    @include media-down(lg) {
      position: relative;
    }
    &.hidden {
      transform: translateY(200px);
      opacity: 0;
    }
  }
}

.l-footer {
  --bs-link-color: #fff;
  --fa-inverse: #0c255e;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $white;
  background: url('/assets/img/footer_bg@2x.jpg') center / cover no-repeat;
  padding: map-get($spacers, 6) map-get($spacers, 3);
  z-index: 0;
  @include media-down(lg) {
    padding-bottom: map-get($spacers, 2);
    row-gap: map-get($spacers, 4);
  }

  &__inner {
    display: grid;
    width: 100%;
    max-width: 1000px;
    row-gap: map-get($spacers, 4);
    @include media-down(lg) {
      row-gap: map-get($spacers, 3);
    }
  }

  &__logo {
    display: flex;
    justify-content: center;
    &-link {
      @include hoverOpacity;
    }
    .img {
      display: block;
      max-width: 100%;
      height: auto;
    }
  }

  &__body.is-primary {
    display: grid;
    @include media-up(lg) {
      justify-self: self-start;
      grid-auto-flow: column;
      grid-template: repeat(2, auto) / repeat(3, auto);
      gap: 1em map-get($spacers, 4);
    }
    @include media-down(lg) {
      grid-template-columns: repeat( 2, 1fr );
      gap: 1em map-get($spacers, 3);
    }
  }

  &__body.is-secondary {
    display: grid;
    @include media-up(lg) {
      align-items: start;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 80px;
    }
    @include media-down(lg) {
      row-gap: map-get($spacers, 4);
    }
  }

  &__access {
    font-size: calc(12 / 16 * $font-size-base);
    letter-spacing: 0.12em;
    font-feature-settings: "palt";
    line-height: 1.9;
    @include media-up(lg) {
      min-width: 200px;
    }
    address {
      font-style: normal;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &.is-large,
    &.is-large {
      @include media-down(lg) {
        grid-column: auto / span 2;
      }
    }
  }

  &__contact {
    display: flex;
    flex-direction: column;
    row-gap: 1em;
    @include media-up(lg) {
      grid-column: 3;
      grid-row: 1 / span 2;
    }
    @include media-down(lg) {
      grid-column: auto / span 2;
      margin-bottom: map-get($spacers, 3);
      align-items: center;
    }
    .btn-outline-white {
      --bs-btn-hover-color: #{$primary};
      --bs-btn-hover-bg: #{$white};
      --bs-btn-hover-border-color:  #{$white};
      --bs-btn-active-color: #{$primary};
      --bs-btn-active-bg:  #{$white};
      --bs-btn-active-border-color:  #{$white};
    }
    .opentime {
      font-size: calc(13 / 16 * $font-size-base);
      letter-spacing: 0.15em;
    }
    .tel {
      display: flex;
      align-items: center;
      column-gap: 0.2em;
      font-family: 'Jost', $font-family-base;
      font-size: min(6.93vw, 30 / 16 * $font-size-base);
      letter-spacing: 0.13em;
      transition: $transition-base;
      line-height: 1;
      &:hover,&:focus{
        text-decoration: none;
        color: $secondary;
      }
      .fa-stack {
        font-size: 0.45em;
      }
    }
    &-btnwrap {
      @include media-down(lg) {
        align-self: stretch;
      }
    }
    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 1em;
      font-family: 'Montserrat', $font-family-base;
      letter-spacing: 0.12em;
      font-weight: $font-weight-normal;
      padding-block: 0.7em;
    }
  }

  &__title {
    font-size: calc(15 / 16 * $font-size-base);
    font-weight: $font-weight-bold;
    letter-spacing: 0.15em;
    margin-bottom: 1.5em;
    &:before {
      display: inline-block;
      content: '';
      height: 1em;
      width: 1em;
      background: svgUrlFunc('<svg viewBox="0 0 11.49 16.28" xmlns="http://www.w3.org/2000/svg"><g fill="#bac0c1"><path d="m8.52 1.94-3.02-1.94-5.5 3.18v6.35l3.02 1.94z"/><path d="m2.96 14.35 3.03 1.93 5.5-3.17v-6.36l-3.02-1.93z"/></g></svg>') center / contain no-repeat;
      vertical-align: middle;
      margin-right: 0.5em;
    }
  }

  &__silikali {
    display: grid;
    column-gap: min(3.3vw, 20px);
    align-items: center;
    .l-footer__title {
      grid-column: auto / span 2;
    }
    &-btnwrap {
      display: flex;
      flex-direction: column;
      row-gap: 0.5em;
    }
    &-qr {
      img{
        display: block;
      }
    }
    .btn {
      display: flex;
      align-items: center;
      justify-content: start;
      text-align: left;
      --bs-btn-padding-y: 0.7em;
      --bs-btn-font-size: #{min(3.46vw, 15 / 16 * $font-size-base)};
      letter-spacing: 0.15em;
      font-feature-settings: "palt";
      @include media-down(lg) {
        --bs-btn-padding-x: 1.3em;
        height: 4em;
      }
    }
    .btn [class*="fa-"] {
      margin-left: auto;
    }
  }

  &__foot {
    display: flex;
    align-items: center;
    color: fade-out($white, 0.5);
    font-size: calc(10 / 16 * $font-size-base);
    letter-spacing: 0.2em;
    font-feature-settings: "palt";
    @include media-up(lg) {
      position: absolute;
      bottom: 0;
      right: 0;
      padding-bottom: 0.7em;
      padding-right: 0.3em;
    }
    @include media-down(lg) {
      flex-direction: column;
      text-align: center;
      row-gap: 1em;
    }
    .icon {
      fill: $white;
      transition: $transition-base;
    }
    .btn:hover .icon {
      fill: fade-out($white, 0.5);
    }
    .btn-link {
      @include media-down(lg) {
        --bs-btn-padding-x: 0.5em;
        --bs-btn-padding-y: 0.2em;
      }
    }
  }
  hr {
    margin-block: 0;
  }
}

.footer-nav {
  &__list,
  &__sublist {
    padding: 0;
    margin: 0 0 0.5em;
    display: flex;
    flex-wrap: wrap;
    column-gap: 1.5em;
    list-style: none;
    font-family: "Jost", $font-family-base;
    letter-spacing: 0.11em;
    font-weight: $font-weight-semi-bold;
    font-size: min(4.26vw, 17 / 16 * $font-size-base);
    line-height: 1.8;
    &:last-child {
      margin-bottom: 0;
    }
    @include media-up(lg) {
      max-width: 350px;
    }
  }
  &__sublist {
    --bs-link-color: #{fade-out($white, 0.5)};
    font-size: calc(11 / 16 * $font-size-base);
    letter-spacing: 0.15em;
  }
}