%text-entry-body {
  line-height: $line-height-lg;
  font-feature-settings: "palt";
  letter-spacing: 0.14em;
  font-size: $font-size-base;
  -webkit-font-smoothing: antialiased;
  @include media-down(md) {
    font-size: $font-size-base * 0.9375;
  }
}

.is-exclamation-mark {
  font-style: normal;
  font-weight: $font-weight-bold;
  font-size: 1.15em;
  color: $red;
}