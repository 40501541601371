@mixin c-module-title() {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.75em;
  font-weight: normal;
  letter-spacing: 0.22em;
  text-indent: 0.22em;
  font-size: $h2-font-size;
  @include media-down(lg) {
    font-size: 1.875rem;
  }
  @include media-down(md) {
    font-size: $h4-font-size;
  }

  span {
    font-family: "Roboto", $font-family-sans-serif;
    font-weight: $font-weight-normal;
  }

  &:after{
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    display: block;
    width: 82px;
    height: 1px;
    margin: auto;
    background: $primary;
  }
}

@mixin c-block-title ($theme: $quinary, $theme2: $quinary, $theme3: #BBC1C2) {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: min(10.6vw, 63 / 16 * $font-size-base);
  font-family: 'Montserrat', $font-family-base;
  font-weight: $font-weight-bold;
  color: $theme;
  letter-spacing: 0.05em;
  margin-bottom: 0.7em;
  small {
    font-size: min( 3.46vw, $font-size-base);
    letter-spacing: 0.2em;
    margin-top: 0.3em;
  }
  small:before {
    display: inline-block;
    content: '';
    height: 1.3em;
    width: 1.3em;
    background: svgUrlFunc('<svg viewBox="0 0 11.49 16.28" xmlns="http://www.w3.org/2000/svg"><g><path fill="#{$theme2}" d="m8.52 1.94-3.02-1.94-5.5 3.18v6.35l3.02 1.94z"/><path fill="#{$theme3}" d="m2.96 14.35 3.03 1.93 5.5-3.17v-6.36l-3.02-1.93z"/></g></svg>') center / contain no-repeat;
    vertical-align: bottom;
    margin-right: 0.4em;
  }
}

@mixin c-section-title($theme: $teal, $theme2: $body-color){
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 1em;
  color: $theme;
  font-size: min(10.6vw, 61 / 16 * $font-size-base);
  font-family: 'Montserrat', $font-family-base;
  letter-spacing: 0.05em;
  font-weight: $font-weight-bold;
  small {
    color: $theme2;
    font-family: $font-family-base;
    letter-spacing: 0.2em;
    font-size: min(3.46vw, $font-size-base);
    margin-top: 0.5em;
  }
}