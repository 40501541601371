@keyframes scroll_line {
  from {
    transform: translateY(-50%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes company_pc {
  0% {
    width: calc(100% + 67px);
    background-color: $primary;
    transform: translateX(-100%);
  }
  50% {
    width: calc(100% + 67px);
    background-color: $primary;
    transform: translateX(0%);
  }
  75% {
    background-color: $white;
  }
  100% {
    background-color: $white;
    width: max(67px, 50vw - 700px + 67px + 80px);
    transform: translateX(0%);
  }
}

.is--home {

  #allbox {
    @include media-down(lg) {
      padding-top: 0;
    }
  }
  .rwd002-main {
    overflow: hidden;
  }
  .rwd002-header {
    box-shadow: none;
    @include media-up(lg) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 1;
      --bs-body-color: #{$white};
    }
  }
  .rwd002-gnav {
    @include media-up(lg) {
      --bs-link-color: #{$white};
      --fa-inverse: #095D8C;
    }
  }
}

.rwd002-header.is-home-first-view {
  @include media-down(lg) {
    background-color: transparent;
    img.is-normal {
      display: none;
    }
    img.is-firstView {
      display: block !important;
    }
  }
}

.is-home-first-view .rwd002-header__drawerBtn {
  @include media-down(lg) {
    --bs-btn-color: #{$white};
  }
}

.mainvisual {
  &__wrap {
    position: relative;
    background-color: $primary;
  }
  &__inner {
    aspect-ratio: 16/9;
    @include media-down(lg) {
      aspect-ratio: 0.729;
    }
  }
  &__headline {
    --bs-link-color: #{$white};
    --bs-link-hover-color: #{$secondary};
    position: absolute;
    background-color: fade-out($primary, 0.2);
    bottom: 0;
    right: 0;
    @include media-down(lg) {
      width: 100%;
    }
    .btn {
      --bs-btn-color: #{$white};
    }
  }
  &__scroll {
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
    writing-mode: vertical-rl;
    column-gap: 1em;
    color: $white;
    letter-spacing: 0.24em;
    font-family: 'Montserrat', $font-family-base;
    font-size: calc(10 / 16 * $font-size-base);
    font-weight: $font-weight-normal;
    z-index: 1;
    @include media-up(lg) {
      bottom: map-get($spacers, 3);
      padding: 0 map-get($spacers, 3);
    }
    @include media-down(lg) {
      display: none;
    }
    .bar {
      height: 70px;
      overflow: hidden;
      &:after {
        display: block;
        content: '';
        height: 200%;
        width: 1px;
        background: linear-gradient(to bottom, $white 50%, fade-out($white, 0.5) 0);
        transform: translateY(-50%);
        animation: scroll_line 1500ms ease-in-out infinite alternate;
      }
    }
  }
}

.block-headline {
  display: flex;
  align-items: center;

  .subject {
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: calc(14 / 16 * $font-size-base);
    letter-spacing: 0.16em;
    margin: 0;
    transition: $transition-base;
    @include media-down(lg) {
      grid-column: 1 / span 2;
    }
  }

  &__title {
    position: relative;
    margin: 0;
    padding: 1em 1em 1em 2em;
    font-family: 'League Spartan', $font-family-base;
    font-size: min( 3.73vw ,$font-size-base);
    letter-spacing: 0.16em;
    color: #2BD1CF;
    &:after {
      content: '';
      position: absolute;
      left: 100%;
      top: 50%;
      height: 1em;
      width: 1px;
      background-color: fade-out($white, 0.5);
      transform: translateY(-50%);
    }
    @include media-down(lg) {
      padding: 1em;
    }
  }

  &__article {
    @include media-up(lg) {
      margin-right: auto;
      max-width: 46vw;
    }
    @include media-down(lg) {
      position: relative;
      width: 100%;
      &:after {
        position: absolute;
        content: '';
        height: 1em;
        width: 15px;
        bottom: 1.5em;
        right: map-get($spacers, 2);
        background: svgUrlFunc('<svg viewBox="0 0 21 4.69" xmlns="http://www.w3.org/2000/svg"><path fill="#{$white}" d="m21 4.69h-21v-1h18.44l-3.21-2.95.66-.74z"/></svg>') center / contain no-repeat;
      }
    }
  }

  &__link {
    display: grid;
    white-space: nowrap;
    padding: 1.5em 1em;
    @include media-up(lg) {
      column-gap: 1.75em;
      grid-template-columns: auto auto 1fr;
    }
    @include media-down(lg) {
      column-gap: 1em;
      padding-right: calc(map-get($spacers, 3) + 1em);
      grid-template-columns: auto 1fr;
      grid-template-rows: auto auto;
    }
    &:hover,&:focus{
      text-decoration: none;
      .subject {
        text-decoration: underline;
        text-underline-offset: 0.2em;
      }
    }
  }

  &__btn {
    font-size: calc(11 / 16 * $font-size-base);
    font-family: 'Mulish', $font-family-base;
    padding: 1em 2em;
    letter-spacing: 0.24em;
    font-weight: $font-weight-semibold;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    column-gap: 1em;
    white-space: nowrap;
    transition: $transition-base;
    @include media-down(lg) {
      display: none;
    }
    &:hover,&:focus{
      background-color: tint($primary, 80%);
    }
  }
}

.block-products {
  padding: map-get($spacers, 6) map-get($spacers, 3) map-get($spacers, 5);
  background:
    url('/assets/img/home/products_moji@2x.png') center -2% / min(1454px,105%) auto no-repeat,
    url('/assets/img/webp/concrete@2x.webp') center top / cover no-repeat
  ;
  @include media-down(lg) {
    background-position: center 1%;
    padding: map-get($spacers, 5) map-get($spacers, 3);
  }
  &__title {
    @include c-block-title();
    margin-bottom: 0;
    @include media-up(lg) {
      grid-row: 1;
      grid-column: 2;
    }
    @include media-down(lg) {
      order: 2;
    }
  }
  &__inner {
    display: grid;
    align-items: center;
    justify-content: center;
    gap: map-get($spacers, 4) map-get($spacers, 3);
    @include media-down(lg) {
      row-gap: 2em;
    }
  }
  &__body {
    @include media-up(lg) {
      max-width: 498px;
      grid-row: 2;
      grid-column: 2;
    }
    @include media-down(lg) {
      order: 3;
    }
    .name {
      display: block;
      margin-bottom: 2em;
      max-width: 100%;
      height: auto;
    }
    p {
      letter-spacing: 0.14em;
      line-height: 1.875;
    }
  }
  &__img {
    @include media-up(lg) {
      padding-right: 1em;
      grid-row: 1 / span 2;
      grid-column: 1;
    }
    @include media-down(lg) {
      order: 1;
      display: flex;
      justify-content: center;
    }
    img {
      display: block;
      max-width: 100%;
      height: auto;
      @include media-down(lg) {
        max-width: 85.5%;
      }
    }
  }
  &__lead {
    display: block;
    font-size: min(5.33vw, 30 / 16 * $font-size-base);
    letter-spacing: 0.16em;
    margin-bottom: 0.7em;
    font-feature-settings: "palt";
  }
  &__btnwrap {
    margin-top: 1.5em;
    .btn {
      width: 100%;
      max-width: 240px;
      letter-spacing: 0.12em;
      font-size: calc(14 / 16 * $font-size-base);
    }
    @include media-down(lg) {
      display: flex;
      justify-content: center;
    }
  }
  &__counter {
    @include media-up(lg) {
      grid-column: auto / span 2;
      justify-self: center;
    }
    @include media-down(lg) {
      order: 4;
      margin-top: 1em;
    }
  }
}

.sk-counter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url('/assets/img/home/products_haikei@2x.png') center / 100% 100% no-repeat;
  color: $primary;
  height: 153px;
  width: 100%;
  font-feature-settings: "palt";
  @include media-up(lg) {
    max-width: 586px;
  }
  @include media-down(sm) {
    background-image: url('/assets/img/home/products_haikei_sp@2x.png');
  }
  &__title {
    font-size: min(4vw, $font-size-base);
    letter-spacing: 0.2em;
    text-indent: 0.2em;
    font-weight: $font-weight-bold;
    margin-bottom: 0.5em;
  }
  &__body {
    font-size: min(5vw, 23 / 16 * $font-size-base);
    font-weight: $font-weight-bold;
    letter-spacing: 0.12em;
    strong {
      font-size: min(10vw, 45 / 16 * $font-size-base);
      letter-spacing: 0.12em;
      line-height: 1;
    }
  }
  &__notice {
    font-size: calc(12 / 16 * $font-size-base);
    font-weight: $font-weight-bold;
    letter-spacing: 0.2em;
    text-indent: 0.2em;
    margin-top: 0.5em;
  }

}

.block-about {
  padding: map-get($spacers, 5) map-get($spacers, 3);
  background: url('/assets/img/webp/home/about_bg@2x.webp') center 90% / cover no-repeat;
  color: $primary;
  font-feature-settings: "palt";
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include media-up(lg) {
    min-height: 820px;
    align-items: center;
    text-align: center;
  }
  @include media-down(lg) {
    background-image: url('/assets/img/webp/home/about_bg_sp@2x.webp');
    padding-bottom: map-get($spacers, 6);  
  }

  &__title {
    @include c-block-title($primary);
    align-items: center;
  }

  &__lead {
    font-size: min(5.14vw, 32 / 16 * $font-size-base);
    font-weight: $font-weight-bold;
    letter-spacing: 0.1em;
    margin-bottom: 1em;
  }
  &__text {
    letter-spacing: 0.14em;
    line-height: 2;
    font-weight: $font-weight-bold;
    @include media-up(lg) {
      max-width: 550px;
    }
  }
  &__btnwrap {
    align-self: stretch;
    margin-top: 1.5em;
    margin-bottom: map-get($spacers, 5);
    .btn {
      width: 100%;
      max-width: 240px;
      letter-spacing: 0.12em;
      font-size: calc(14 / 16 * $font-size-base);
    }
    @include media-down(lg) {
      display: flex;
      justify-content: center;
    }
  }
}

.block-case {
  position: relative;
  z-index: 1;
  &.lazyhide {
    opacity: 1;
  }
  &__inner {
    padding-block: map-get($spacers, 4) map-get($spacers, 7);

    @include media-down(lg) {
      padding-top: map-get($spacers, 1);
      padding-bottom: map-get($spacers, 5);
    }
  }
  &__head {
    padding-inline: map-get($spacers, 3);
    display: flex;
    justify-content: center;
    @include media-down(lg) {
      justify-content: flex-end;
      padding-inline: map-get($spacers, 5);
    }
  }
  &__title {
    @include c-block-title($white,$teal,$white);
    align-items: center;
    position: relative;
    max-width: 960px;
    @include media-up(lg) {
      flex-direction: row;
      align-items: baseline;
      justify-content: flex-end;
      column-gap: 0.4em;
      width: 100%;
    }
    &:after {
      position: absolute;
      top: 50%;
      right: -14%;
      content: '';
      background: url('/assets/img/home/case_title_bg@2x.png') center / contain no-repeat;
      width: min(50vw, 485px);
      height: 687px;
      z-index: -1;
      transform: translateY(-36%);
      @include media-down(lg) {
        height: auto;
        right: 0;
        aspect-ratio: 0.70;
        transform: translate( calc(50% - 1.3em), -44%);
      }
    }
  }

  &__body {
    position: relative;
    max-width: 100%;
    padding-left: calc(50vw - 550px);
    @include media-up(lg) {
      margin-bottom: map-get($spacers, 6);
    }
    @media (max-width: 1130px) {
      padding-left: map-get($spacers, 3);
    }
  }

  &__sliderNav {
    --swiper-pagination-color: #{$primary};
    --swiper-navigation-size: 31px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 100%;
    width: 100%;
    max-width: 1100px;
    column-gap: 10px;
    padding-top: map-get($spacers, 3);
    @include media-down(lg) {
      position: static;
      column-gap: 1em;
      padding-right: map-get($spacers, 3);
    }
  }
  .swiper-pagination-progressbar,
  .swiper-button-prev,
  .swiper-button-next {
    position: static;
    margin: 0;
    flex-shrink: 0;
    width: auto;
  }
  .swiper-pagination-progressbar {
    flex-grow: 1;
    height: 2px;
    margin-right: 1.5em;
    background-color: #E7E7E7;
    @include media-down(lg) {
      margin-right: 1em;
    }
  }
  .swiper-button-prev:after,
  .swiper-button-next:after{
    width: 1em;
    aspect-ratio: 0.86;
  }

  .swiper-button-prev:after{
    content: '';
    background: svgUrlFunc('<svg viewBox="0 0 31 36" xmlns="http://www.w3.org/2000/svg"><g fill="#c1c1c1"><path d="m16.85 24.2-6.83-6.86 6.83-6.86 1.41 1.41-5.41 5.45 5.41 5.44z"/><path d="m15.5 36-15.5-9v-18l15.5-9 15.5 9v18zm-13.5-10.16 13.5 7.84 13.5-7.84v-15.68l-13.5-7.84-13.5 7.84z"/></g></svg>') center / contain no-repeat;
  }
  .swiper-button-next:after{
    content: '';
    background: svgUrlFunc('<svg viewBox="0 0 31 36" xmlns="http://www.w3.org/2000/svg"><g fill="#c1c1c1"><path d="m14.15 25.53-1.41-1.42 5.41-5.45-5.41-5.44 1.41-1.42 6.83 6.86z"/><path d="m15.5 36-15.5-9v-18l15.5-9 15.5 9v18zm-13.5-10.16 13.5 7.84 13.5-7.84v-15.68l-13.5-7.84-13.5 7.84z"/></g></svg>') center / contain no-repeat;
  }

  .case-item {
    --case-item-cat-color: #A9A9A9;
    width: min( 60vw, 330px);
    z-index: 0;
  }

  &__btnwrap {
    display: flex;
    justify-content: center;
    margin-top: 1.5em;
    padding-inline: map-get($spacers, 3);
    .btn {
      width: 100%;
      max-width: 240px;
      letter-spacing: 0.12em;
      font-size: calc(14 / 16 * $font-size-base);
    }
  }
}

.block-company {
  position: relative;
  display: flex;
  justify-content: center;
  color: $white;
  z-index: 0;
  margin-bottom: map-get($spacers, 6);
  @include media-up(lg) {
    background: url('/assets/img/webp/home/company_bg@2x.webp') -9999% -9999% / cover no-repeat;
    transition: background-position 0s 600ms;
  }
  @include media-down(lg) {
    margin-bottom: map-get($spacers, 4);
  }
  &.lazyhide {
    opacity: 1;
  }
  &.is-intersection {
    background-position: center;
  }
  &:after {
    content: '';
    position: absolute;
    height: auto;
    width: auto;
    @include media-up(lg) {
      background-color: $primary;
      top: -0.5px;
      right: 0;
      bottom: -0.5px;
      width: calc(100% + 67px);
      transform: translateX(-100%);
    }
    @include media-down(lg) {
      background: url('/assets/img/webp/home/company_bg_sp@2x.webp') center / cover no-repeat;
      top: 100px;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: -1;
      clip-path: polygon(0 50px, 50% 0, 100% 50px, 100% calc(100% - 50px), 50% 100%, 0 calc(100% - 50px) );
    }
  }
  &.is-intersection:after{
    @include media-up(lg) {
      animation: company_pc 1200ms ease-in-out forwards;
      width: max(67px, 50vw - 700px + 67px);
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 67px 50%);
    }
  }
  &__inner {
    display: grid;
    row-gap: map-get($spacers, 3);
    width: 100%;
    max-width: 1400px;
    opacity: 0;
    transition: $transition-base;
    transition-delay: 0.6s;
    @include media-up(lg) {
      position: relative;
      grid-template-columns: 48% 52%;
      align-items: center;
    }
  }
  &.is-intersection &__inner {
    opacity: 1;
  }

  &__title {
    @include c-block-title(#17D1CF,$teal,$white);
    @include media-up(lg) {
      margin-bottom: 0.3em;
    }
    span {
      @include media-up(lg) {
        position: absolute;
        top: 0;
        right: min(80px, 50vw - 700px + 75px );
        z-index: 1;
        margin-top: 0.5em;
      }
      @media (max-width:1240px) {
        right: 0;
      }
    }
    small {
      color: $white;
    }
  }

  &__body {
    order: 2;
    @include media-down(lg) {
      padding: 0 map-get($spacers, 3) map-get($spacers, 5);
    }
    p {
      line-height: 2;
      letter-spacing: 0.14em;
    }
  }

  &__img {
    display: flex;
    order: 1;
    position: relative;
    z-index: 1;
    @include media-up(lg) {
      transition: opacity 0.3s 1200ms ease-in-out;
      opacity: 0;
      align-items: center;
      max-height: 495px;
      padding-right: map-get($spacers, 3);
    }
    img {
      height: auto;
      @include media-up(lg) {
        max-width: 40vw;
      }
      @include media-down(lg) {
        max-width: 73.33vw;
      }
    }
  }
  &.is-intersection &__img {
    @include media-up(lg) {
      opacity: 1;
    }
  }
  &__btnwrap {
    align-self: stretch;
    margin-top: 1.5em;
    @include media-down(lg) {
      display: flex;
      justify-content: center;
    }
    .btn {
      width: 100%;
      max-width: 240px;
      letter-spacing: 0.12em;
      font-size: calc(14 / 16 * $font-size-base);
    }
  }
}


//お知らせ
.block-news {
  position: relative;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  z-index: 0;
  @include media-up(lg) {
  }
  &__inner {
    max-width: 100%;
    width: calc(1090px + calc(map-get($spacers, 3) * 2));
    margin: map-get($spacers, 6) auto;
    padding-inline: map-get($spacers, 3);
    @include media-up(lg) {
      display: grid;
      gap: map-get($spacers, 4) 80px;
      grid-template-columns: auto 1fr;
      grid-template-rows: auto 1fr;
    }
    @include media-down(lg) {
      display: flex;
      flex-direction: column;
    }
  }
  &__head {

  }
  &__list-wrap {
    width: 100%;
    @include media-up(lg) {
      max-width: 100%;
      flex-grow: 1;
      grid-row: auto / span 2;
      grid-column: 2;
    }
  }
  &__btnwrap {
    @include media-down(lg) {
      align-self: flex-end;
    }
  }
  &__list {
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
    @include media-up(lg) {
      margin-top: -1.5rem;
    }
  }
  &__title {
    @include c-block-title();
    align-items: flex-start;
    text-align: left;
    @include media-up(lg) {
      margin-bottom: 0;
    }
    @include media-down(lg) {
      margin-bottom: 0.2em;
    }
  }
  &__head-btn {
    @include hoverOpacity;
    padding-left: 0;
    padding-right: 0;
    font-family: 'Lato', $font-family-base;
    font-size: $font-size-sm;
    letter-spacing: 0.16em;
    border-bottom: 1px solid #DFE0E0;
    border-radius: 0;

    @include media-down(lg) {
      margin-left: auto;
      font-size: $font-size-base * 0.8125;
    }
    span {
      transition: $transition-base;
    }
    &:hover,
    &:focus {
      text-decoration: none;
      color: $primary;
    }
    .fa-list {
      margin-left: 0.5em;
      color: $primary;
    }
  }
}

.block-news-item {
  @include list-common__item();
  position: relative;
  &__link {
    display: block;
    color: inherit;
    padding: map-get($gutters, 2) 0;
    transition: $transition-base;
    &:hover,
    &:focus {
      color: $primary;
      text-decoration: none;
      .date,
      .cate,
      .title.is-new:after,
      .price,
      .price.is-regular,
      .comment {
        color: inherit;
      }
    }
    &:after {
      position: absolute;
      top: 50%;
      right: 1em;
      line-height: 0;
      content: '\f105';
      font-family: 'Font Awesome 6 Free';
      font-weight: 900;
    }
  }

  &__body {
    @include media-up(lg) {
      display: flex;
      flex-direction: column;
    }
  }

  .title {
    flex-grow: 1;
    font-weight: $font-weight-bold;
    font-size: calc(18/16 * $font-size-base);
    @include media-down(lg) {
      font-size: $font-size-base;
    }
  }

  .date-cate {
    @include media-up(lg) {
      margin-right: 1em;
    }
    @include media-down(lg) {
      margin-bottom: 0.5em;
    }
  }
  
  .date {
    margin-bottom: 0;
    font-size: calc(13/16 * $font-size-base);
    color: #808080;
    letter-spacing: .05em;
    @include media-down(md) {
      display: inline-block;
      vertical-align: middle;
    }
  }
  
  .cate {
    vertical-align: middle !important;
    color: $teal;
    background: none;
    min-width: auto;
    margin: 0;
    padding: 0;
    border: none;
    @include media-down(md) {
      max-width: calc(50vw - map-get($spacers, 3) - map-get($gutters, 3));
    }
  }

  & + & {
    border: solid #DFE0E0;
    border-width: 1px 0 0;
    // &:before {
    //   content: '';
    //   position: absolute;
    //   bottom: 100%;
    //   left: 0;
    //   display: block;
    //   width: 28px;
    //   height: 1px;
    //   background-color: $body-color;
    // }
  }
}