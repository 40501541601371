.is--products.is--article-1 {
  .rwd002-breadcrumbs {
    display: none;
  }
}

.is--products .rwd002-list-content {
  &__inner {
    max-width: 927px;
  }
}

.is-products .rwd002-list-content {
  &__item {
    margin-top: map-get($spacers, 4);
    padding-bottom: map-get($spacers, 4);
    border-bottom: 1px solid #e7ebf0;
    &:last-child {
      border-bottom-width: 0;
    }
  }
  &__link {
    row-gap: map-get($spacers, 3);
    column-gap: map-get($spacers, 4);
    @include media-down(lg) {
      flex-direction: column;
      align-items: stretch;
    }
    &:hover .title,
    &:focus .title{
      color: $primary;
      &:after {
        background-image: svgUrlFunc('<svg viewBox="0 0 23 27" xmlns="http://www.w3.org/2000/svg"><path d="m11.5 0-11.5 6.75v13.5l11.5 6.75 11.5-6.75v-13.5zm.22 18.79-1.33-1.47 2.78-2.59h-6.73v-2h6.73l-2.78-2.59 1.33-1.47 5.43 5.07-5.43 5.07z" fill="#{$primary}"/></svg>');
      }
    }
  }
  &__thumb {
    aspect-ratio: auto;
    width: 460px;
    max-width: 100%;
    margin: 0;
    &:before {
      display: none;
    }
  }
  &__thumb-img {
    position: static;
    aspect-ratio: 1.68;
    height: auto;
    width: 100%;
  }
  &__body {
    .title {
      transition: $transition-base;
      color: #003770;
      font-size: calc(22 / 16 * $font-size-base);
      font-weight: $font-weight-bold;
    }
    .title:after {
      content: '';
      display: inline-block;
      height: 1.1em;
      width: 1em;
      margin-left: 0.5em;
      vertical-align: text-bottom;
      background: svgUrlFunc('<svg viewBox="0 0 23 27" xmlns="http://www.w3.org/2000/svg"><path d="m11.5 0-11.5 6.75v13.5l11.5 6.75 11.5-6.75v-13.5zm.22 18.79-1.33-1.47 2.78-2.59h-6.73v-2h6.73l-2.78-2.59 1.33-1.47 5.43 5.07-5.43 5.07z" fill="#{$primary}"/></svg>') center / contain no-repeat;
    }
    .desc {
      font-weight: $font-weight-normal;
      line-height: 2;
      letter-spacing: 0.14em;
      font-size: calc(15 / 16 * $font-size-base);
    }
  }
}

.silikali-intro {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: map-get($spacers, 7) map-get($spacers, 3) 185px;
  background:
    url('/assets/img/home/products_moji@2x.png') center -2% / min(1454px,105%) auto no-repeat,
    url('/assets/img/webp/concrete@2x.webp') center top / cover no-repeat
  ;
  @include media-down(lg) {
    background-position: center 1%;
    padding: map-get($spacers, 5) map-get($spacers, 3) map-get($spacers, 7);
  }

  &__img {
    @include media-down(lg) {
      margin-bottom: 1em;
    }
    img {
      display: block;
      max-width: 100%;
    }
  }

  &__lead {
    text-align: center;
    font-size: min(5.33vw, 23 / 16 * $font-size-base);
    letter-spacing: 0.16em;
    line-height: 1.6;
    font-weight: $font-weight-bold;
    font-feature-settings: "palt";
    &:last-child {
      margin-bottom: 0;
    }
  }
  .sk-counter {
    position: absolute;
    bottom: 0;
    transform: translateY(50%);
    @include media-down(lg) {
      max-width: calc(100% - map-get($spacers, 3));
    }
  }
}

.silikali-about {
  background: url('/assets/img/webp/products/products_about_bk@2x.webp') center / cover no-repeat;
  padding: 165px map-get($spacers, 3) map-get($spacers, 6);
  color: $white;
  @include media-down(lg) {
    padding: 130px map-get($spacers, 3) map-get($spacers, 5);
  }
  &__title {
    @include c-section-title($teal, $white);
  }
  &__container {
    display: grid;
    column-gap: map-get($spacers, 5);
    row-gap: map-get($spacers, 3);
    @include media-up(lg) {
      grid-auto-flow: column;
      justify-content: center;
    }
  }

  &__body {
    line-height: 1.875;
    letter-spacing: 0.12em;
    .lead {
      display: block;
      font-weight: $font-weight-bold;
      letter-spacing: 0.16em;
      line-height: 1.57;
      font-feature-settings: "palt";
      font-size: min(5.33vw, 28 / 16 * $font-size-base);
      margin-bottom: 0.7em;
    }
    p {
      margin-bottom: 1.5em;
    }
    @include media-up(lg) {
      max-width: 485px;
    }
  }
  &__targets,
  &__guide {
    border-top: 1px solid fade-out($white, 0.7);
    padding-block: 1.5em;
    .title {
      font-size: calc(18 / 16 * $font-size-base);
      font-weight: $font-weight-bold;
      letter-spacing: 0.15em;
      margin-bottom: 1em;
      font-feature-settings: "palt";
      span {
        display: inline-block;
        margin-right: 0.3em;
      }
      small {
        font-weight: $font-weight-normal;
        display: inline-block;
        font-size: 0.722em;
        letter-spacing: 0.15em;
        margin-top: 0.7em;
      }
      &:before {
        display: inline-block;
        content: '';
        height: 1em;
        width: 1em;
        background: svgUrlFunc('<svg viewBox="0 0 11.49 16.28" xmlns="http://www.w3.org/2000/svg"><g fill="#bac0c1"><path d="m8.52 1.94-3.02-1.94-5.5 3.18v6.35l3.02 1.94z"/><path d="m2.96 14.35 3.03 1.93 5.5-3.17v-6.36l-3.02-1.93z"/></g></svg>') center / contain no-repeat;
        vertical-align: middle;
        margin-right: 0.5em;
      }
    }
  }

  &__targets {
    &-list {
      margin-block: 0 map-get($spacers, 3);
      padding-left: 1em;
      font-size: calc(15 / 16 * $font-size-base);
      font-weight: $font-weight-bold;
      letter-spacing: 0.16em;
      column-count: 2;
      column-gap: 1em;
      @include media-up(lg) {
        column-count: 3;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    &-item {
      break-inside: avoid;
    }
  }

  &__guide {
    display: grid;
    column-gap: min(3.3vw, 20px);
    grid-template-columns: 1fr auto;
    align-items: center;
    .title {
      grid-column: auto / span 2;
    }
    &-btnwrap {
      display: flex;
      flex-direction: column;
      row-gap: 0.5em;
    }
    &-qr {
      img{
        display: block;
      }
    }
    .btn {
      display: flex;
      align-items: center;
      justify-content: start;
      text-align: left;
      --bs-btn-padding-y: 0.7em;
      --bs-btn-font-size: #{min(3.46vw, 15 / 16 * $font-size-base)};
      letter-spacing: 0.15em;
      font-feature-settings: "palt";
      @include media-down(lg) {
        --bs-btn-padding-x: 1.3em;
        height: 4em;
      }
    }
    .btn [class*="fa-"] {
      margin-left: auto;
    }
  }

  &__img {
    display: flex;
    justify-content: center;
    img {
      @include media-up(lg) {
        max-width: 40vw;
      }
      @include media-down(lg) {
        max-width: 100%;
      }
    }
  }
  
}

.silikali-points {
  margin-top: map-get($spacers, 7);
  &:first-child {
    margin-top: 0;
  }
  @include media-down(lg) {
    margin-top: map-get($spacers, 5);
  }
  &__title {
    @include c-section-title();
  }
}

.silikali-point {
  display: flex;
  column-gap: map-get($spacers, 5);
  row-gap: map-get($spacers, 3);
  @include media-up(lg) {
    justify-content: flex-end;
    align-items: center;
    height: 472px;
  }
  @include media-down(lg) {
    flex-direction: column;
  }
  &:nth-child(odd){
    background-color: #F5F5F5;
    @include media-up(lg) {
      flex-direction: row-reverse;
    }
  }
  &:nth-child(odd) &__body {
    @include media-up(lg) {
    }
  }

  &__body {
    line-height: 2;
    letter-spacing: 0.1em;
    padding-inline: map-get($spacers, 3);
    @include media-up(lg) {
      max-width: calc(597px + map-get($spacers, 3) * 2);
    }
    @include media-down(lg) {
      order: 2;
      padding-bottom: map-get($spacers, 5);
    }
  }

  &__title {
    display: flex;
    align-items: baseline;
    column-gap: 0.8em;
    font-size: min(7.45vw, 35 / 16 * $font-size-base);
    .num,
    .text {
      display: flex;
      flex-direction: column;
    }
    .num {
      align-items: center;
      font-size: calc(53 / 16 * $font-size-base);
      font-family: 'Roboto', $font-family-base;
      letter-spacing: 0.02em;
      text-indent: 0.02em;
      color: $teal;
      line-height: 1;
      small {
        font-family: 'Montserrat', $font-family-base;
        font-size: 0.26em;
        letter-spacing: 0.05em;
        text-indent: 0.05em;
        font-weight: $font-weight-bold;
      }
    }
    .text {
      font-weight: $font-weight-bold;
      letter-spacing: 0.18em;
      row-gap: 0.2em;
      color: $primary;
      font-feature-settings: "palt";
      small {
        font-size: 0.5em;
        letter-spacing: 0.18em;
      }
    }
  }
  &__btnwrap {
    display: flex;
    flex-wrap: wrap;
    column-gap: min(5.3vw, 3em);
    row-gap: 1em;
    margin-top: 2em;
  }
  &__btnwrap .btn-link{
    padding: 0.5em 0;
    border-bottom: solid currentColor;
    border-width: 0 0 2px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: min(50% - 2.65vw ,155px);
    color: $primary;
    font-size: min(3.46vw, 15 / 16 * $font-size-base);
    font-weight: $font-weight-bold;
    &:hover,&:focus{
      text-decoration: none;
      color: tint($primary, 10%);
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2em;
    background: url('/assets/img/products/products_point_orange@2x.png') center / cover no-repeat;
    align-self: stretch;
    @include media-up(lg) {
      width: 35%;
    }
    @include media-down(lg) {
      order: 1;
      clip-path: polygon(0 0, 100% 0, 100% calc(100% - 48px), 50% 100%, 0 calc(100% - 48px));
    }
    img {
      display: block;
      height: auto;
      max-width: min(33vw,100%);
    }
  }
  &:nth-child(odd) &__icon {
    @include media-up(lg) {
      padding-right: map-get($spacers, 5);
      clip-path: polygon(0 0, calc(100% - 60px) 0, 100% 50%, calc(100% - 60px) 100%, 0 100%);
    }
  }
  &:nth-child(even) &__icon {
    @include media-up(lg) {
      padding-left: map-get($spacers, 5);
      clip-path: polygon(60px 0, 100% 0, 100% 100%, 60px 100%, 0 50%);
    }
  }
}

.silikali-mechanism {
  background: url('/assets/img/webp/products/products_mechanism_bg@2x.webp') center / cover no-repeat;
  color: $white;
  padding: map-get($spacers, 6) map-get($spacers, 3);
  margin-bottom: map-get($spacers, 6);
  @include media-down(lg) {
    margin-bottom: map-get($spacers, 5);
  }
  &__title {
    @include c-section-title($teal, $white)
  }

  &__container {
    --swiper-pagination-color: #{$teal};
    --swiper-pagination-bullet-inactive-color: #{$white};
    --swiper-pagination-bullet-inactive-opacity: 1;
    --swiper-pagination-bullet-horizontal-gap: 0.6em;
    position: relative;
    max-width: 716px;
    margin-inline: auto;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(-40%, -40%);
      background: center / contain no-repeat;
      aspect-ratio: 73/85;
      width: min(8.26vw, 73px);
      z-index: 2;
    }
    @include media-down(lg) {
      max-width: 282px;
    }
  }
  &__container[data-slider-index="0"]:before {
    background-image: url('/assets/img/products/products_mechanism_number01.svg');
  }
  &__container[data-slider-index="1"]:before {
    background-image: url('/assets/img/products/products_mechanism_number02.svg');
  }
  &__container[data-slider-index="2"]:before {
    background-image: url('/assets/img/products/products_mechanism_number03.svg');
  }
  &__container[data-slider-index="3"]:before {
    background-image: url('/assets/img/products/products_mechanism_number04.svg');
  }
  &__container[data-slider-index="4"]:before {
    background-image: url('/assets/img/products/products_mechanism_number05.svg');
  }
  &__container[data-slider-index="5"]:before {
    background-image: url('/assets/img/products/products_mechanism_number06.svg');
  }

  &__slider {
  }

  &__slide {
    img {
      display: block;
      max-width: 100%;
      height: auto;
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    @include hoverOpacity;
    aspect-ratio: 1;
    width: min(6vw, 76px);
    height: auto;
    transform: translateY(-50%);
    &:after {
      display: none;
    }
  }
  .swiper-button-next {
    right: auto;
    left: calc(100% + 10px);
    background: svgUrlFunc('<svg enable-background="new 0 0 76 76" viewBox="0 0 76 76" xmlns="http://www.w3.org/2000/svg"><circle cx="37.651" cy="37.651" fill="#fff" r="37.651"/><path d="m30.989 19.871 19.85 17.78-19.85 17.781" style="fill:none;stroke:#012562;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10"/></svg>') center / contain no-repeat;
  }
  .swiper-button-prev {
    left: auto;
    right: calc(100% + 10px);
    background: svgUrlFunc('<svg enable-background="new 0 0 76 76" viewBox="0 0 76 76" xmlns="http://www.w3.org/2000/svg"><circle cx="37.651" cy="37.651" fill="#fff" r="37.651"/><path d="m44.313 55.432-19.849-17.781 19.849-17.78" style="fill:none;stroke:#012562;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10"/></svg>') center / contain no-repeat;
  }
  .swiper-pagination {
    position: static;
    margin-top: 1em;
  }
  .swiper-pagination-bullet-active {
    box-shadow: 0 0 0 6px fade-out($teal, 0.5);
  }
}

.silikali-step {
  margin-block: map-get($spacers, 5);
  padding-inline: map-get($spacers, 3);
  &:last-child {
    margin-bottom: map-get($spacers, 6);
  }

  &__title {
    @include c-section-title();
  }
  &__img {
    display: flex;
    justify-content: center;
    padding: 0 0 map-get($spacers, 5);
    max-width: 1024px;
    margin-inline: auto;
    border-bottom: 1px solid #EBEBEB;
    @include media-up(lg) {
      margin-top: -2em;
    }
    img {
      display: block;
      max-width: 100%;
      height: auto;
    }
  }
}

.silikali-guarantee {
  margin-block: map-get($spacers, 5);
  &:last-child {
    margin-bottom: map-get($spacers, 6);
  }

  &__title {
    @include c-section-title();
  }
  &__container {
    display: grid;
    padding-inline: map-get($spacers, 3);
    @include media-up(lg) {
      column-gap: map-get($spacers, 5);
      grid-auto-flow: column;
      justify-content: center;
    }
  }
  &__head {
    line-height: 2;
    letter-spacing: 0.12em;
    @include media-up(lg) {
      max-width: 260px;
    }
    strong {
      display: block;
      font-size: min(5.6vw, 25 / 16 * $font-size-base);
      color: $primary;
      letter-spacing: 0.1em;
      margin-bottom: 0.4em;
      line-height: 1.5;
    }
  }
  &__table {
    table-layout: fixed;
    width: 100%;
    @include media-up(lg) {
      max-width: 460px;
    }
    th,td {
      font-size: calc(15 / 16 * $font-size-base);
      letter-spacing: 0.15em;
      padding: 0.7em 1.5em;
      border: 1px solid #EBEBEB;
      text-align: center;
      font-feature-settings: "palt";
    }
    th {
      background-color: #4E638A;
      color: $white;
      @include media-up(lg) {
        white-space: nowrap;
      }
    }
  }
  &__btnwrap {
    display: flex;
    justify-content: center;
    padding-inline: map-get($spacers, 3);
    margin-top: map-get($spacers, 6);
    @include media-down(lg) {
      margin-top: map-get($spacers, 5);
    }
    .btn {
      --bs-btn-padding-y: 1.2em;
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 1em;
      width: 100%;
      max-width: 555px;
      letter-spacing: 0.1em;
      font-size: min(4.53vw, 20 / 16 * $font-size-base);
      @include media-up(lg) {
        height: 143px;
      }
    }
    .btn .icon {
      @include media-down(lg) {
        height: auto;
        max-width: 8vw;
      }
    }
  }
}