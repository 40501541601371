.rwd002-moduletitle {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: map-get($spacers, 5) map-get($spacers, 3);
  text-align: center;
  &__inner {
    @include c-block-title($quinary);
    font-size: min(8.53vw, 63 / 16 * $font-size-base);
    margin-bottom: 0;
    padding-inline: map-get($spacers, 3);
    width: 100%;
    max-width: calc(1400px + map-get($spacers, 3) * 2);
    small {
      font-size: min( 2.94vw, $font-size-base);
    }
  }

  &.is-about {
    padding: map-get($spacers, 3);
    max-width: 100%;
    background: url('/assets/img/webp/about/about_moduletitle_bg@2x.webp') center / cover no-repeat;
    @include media-up(lg) {
      height: 345px;
    }
    @include media-up(xl) {
      padding-inline: map-get($spacers, 6);
    }
  }
  &.is-about &__inner {
    color: $primary;
  }

  &.is-company {
    padding: map-get($spacers, 3);
    max-width: 100%;
    background: url('/assets/img/webp/copmany/company_haikei@2x.webp') center / cover no-repeat;
    @include media-up(lg) {
      height: 345px;
    }
    @include media-up(xl) {
      padding-inline: map-get($spacers, 6);
    }
  }
  &.is-company &__inner {
    color: $white;
  }
}