.rwd002-taglist{
  margin-top: map-get($spacers, 4);
  margin-bottom: map-get($spacers, 4);
  padding-left: map-get($spacers, 3);
  padding-right: map-get($spacers, 3);
  @include media-down(lg) {
    margin-top: map-get($spacers, 4);
    margin-bottom: map-get($spacers, 4);
  }

  .rwd002-container + & {
    margin-top: map-get($spacers, 6);
    margin-bottom: map-get($spacers, 6);
  }

  .is-main &{
    @include media-up(xl) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  
  &__cats,
  &__tags {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    font-feature-settings: "palt";
  }

  &__cats {
    flex-wrap: wrap;
    row-gap: 0.5rem;
    column-gap: 0.8rem;
    @include media-down(md) {
      justify-content: flex-start;
    }
  }

  &__catBtn {
    @extend .btn;
    @extend .btn-hexagon;
    @extend .text-truncate;
    --bs-btn-bg: #EFEFEF;
    --bs-btn-hover-bg: #{$primary};
    --bs-btn-active-bg: #{$primary};
    --bs-btn-color: #{$primary};
    --bs-btn-active-color: #{$white};
    --bs-btn-hover-color: #{$white};
    padding-left: 1.2em;
    padding-right: 1.2em;
    // border-radius: 0;
    font-size: $font-size-sm;
    width: 182px;
    letter-spacing: 0.12em;
    text-indent: 0.12em;
    @include media-up(md) {
      max-width: calc(20% - 0.64rem);
    }
    @include media-down(md) {
      width: calc(50% - 0.4rem);
      padding-top: 0.3em;
      padding-bottom: 0.3em;
      font-size: calc(13 / 16 * $font-size-base);
    }
    &.current {
      @extend .btn-primary;
    }
  }

  &__tags {
    flex-direction: column;
    margin-top: 1em;
    &:first-child{
      margin-top: 0;
    }
  }

  &__list {
    max-width: 800px;
    @include media-down(md) {
      width: calc(100% + 1rem);
      margin-left: -0.5rem;
      margin-right: -0.5rem;
    }
  }

  &__btnwrap {
    margin-top: 0.4em;
    &:first-child{
      margin-top: 0;
    }
  }

  &__tagBtn {
    @extend .btn;
    @extend .btn-sm;
    font-weight: $font-weight-normal;
    font-size: $font-size-sm;
    color: $primary;
    letter-spacing: 0.14em;
    @include media-down(lg) {
      font-size: $font-size-xs;
    }
  }

  &__tglBtn {
    @extend .btn;
    @extend .btn-sm;
    &:focus{
      box-shadow: none;
    }
    font-size: $font-size-base * .8125;
  }
}