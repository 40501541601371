.is--case .rwd002-detail__category {
  --bs-btn-border-color: transparent;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-bg: transparent;
  --bs-btn-hover-bg: transparent;
  --bs-btn-color: #A9A9A9;
  text-align: left;
  padding-inline: 0;
  &:before {
    display: inline-block;
    content: '';
    width: 1em;
    height: 1em;
    background-color: currentColor;
    margin-right: 0.6em;
    background: svgUrlFunc('<svg viewBox="0 0 11.49 16.28" xmlns="http://www.w3.org/2000/svg"><g><path fill="#A9A9A9" d="m8.52 1.94-3.02-1.94-5.5 3.18v6.35l3.02 1.94z"/><path fill="#A9A9A9" d="m2.96 14.35 3.03 1.93 5.5-3.17v-6.36l-3.02-1.93z"/></g></svg>') center / contain no-repeat;
  }
}

.case-list {
  display: grid;
  place-items: center;
  padding: 0 map-get($spacers, 3);
  &__inner {
    width: 100%;
    max-width: 1145px;
    display: grid;
    grid-template-columns: repeat( 3, 1fr );
    column-gap: 40px;
    row-gap: map-get($spacers, 5);
    @include media-down(lg) {
      grid-template-columns: repeat( 2, 1fr );
    }
    @include media-down(sm) {
      grid-template-columns: repeat( 1, 1fr );
    }
  }

  &__title {
    font-weight: $font-weight-bold;
    font-size: calc(18 / 16 * $font-size-base);
    letter-spacing: 0.1em;
    text-indent: 0.1em;
    margin-bottom: 1.5em;
  }
}

.case-item{
  --case-item-cat-color: #A9A9A9;
  transition: $transition-base;
  &:hover,&:focus{
    text-decoration: none;
  }

  .cat {
    display: block;
    margin-bottom: 1em;
    font-size: calc(11 / 16 * $font-size-base);
    font-weight: $font-weight-bold;
    letter-spacing: 0.14em;
    color: var(--case-item-cat-color, inherit);
    &:before {
      display: inline-block;
      content: '';
      width: 1em;
      height: 1em;
      background-color: currentColor;
      margin-right: 0.6em;
      background: svgUrlFunc('<svg viewBox="0 0 11.49 16.28" xmlns="http://www.w3.org/2000/svg"><g><path fill="#A9A9A9" d="m8.52 1.94-3.02-1.94-5.5 3.18v6.35l3.02 1.94z"/><path fill="#A9A9A9" d="m2.96 14.35 3.03 1.93 5.5-3.17v-6.36l-3.02-1.93z"/></g></svg>') center / contain no-repeat;
    }
  }
  .title {
    font-size: calc(17 / 16 * $font-size-base);
    font-weight: $font-weight-bold;
    letter-spacing: 0.12em;
  }
  .tags {
    @include text-truncate;
    row-gap: 0.2em;
    color: $primary;
    font-size: calc(11 / 16 * $font-size-base);
    letter-spacing: 0.14em;
    margin-top: 1em;
  }
  .tag {
    display: inline;
    margin-right: 1em;
    &:last-child {
      margin-right: 0;
    }
  }

  &:hover &__img{
    transform: scale(1.1);
  }
  
  &__img {
    display: block;
    width: 100%;
    aspect-ratio: 0.919;
    height: auto;
    object-fit: cover;
    transition: $transition-base;
    &-wrap {
      overflow: hidden;
    }
  }

  &__body{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    z-index: 2;
    padding: 1em 0 0;
  }
}

.case-detail-tags {
  display: flex;
  flex-wrap: wrap;
  column-gap: 1em;
  margin: -1 * map-get($spacers, 4) 0 map-get($spacers, 4);
  font-size: calc(12 / 16 * $font-size-base);
  .tag {
    color: $primary;
    letter-spacing: 0.08em;
    font-weight: $font-weight-bold;
  }
}

.case-detail-table {
  @include table-typeA;
  margin-block: map-get($spacers, 5);
  &:last-child {
    margin-bottom: 0;
  }
}