//
// Color system
//

// stylelint-disable
$white:    #fff !default;
$gray-100: #f4f4f4;
$gray-200: #e9ecef !default;
$gray-300: #D8D8D8;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #818181;
$gray-700: #495057 !default;
$gray-800: #373737;
$gray-900: #272727;
$black:    #000 !default;

$blue:    #074FA1;
$indigo:  #0C255E;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #E40113;
$orange:  #fd7e14 !default;
$yellow:  #FDC805;
$green:   #28a745 !default;
$teal:    #66CECE;
$cyan:    #17a2b8 !default;

$primary:       $indigo;
$secondary:     $teal;
$tertiary:      $red;
$quaternary:    $yellow;
$quinary:       $blue;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-300 !default;
$dark:          $gray-900;

$sns-facebook-bg: #1877F2;
$sns-twitter-bg: #278BCC;
$sns-line-bg: #00b900;
$sns-gplus-color: #db4437;
$sns-insta-bg: #E1306C;
$sns-youtube-bg: #ff0000;

$theme-colors: ();
$theme-colors: map-merge((
  "primary":    $primary,
  "secondary":  $secondary,
  "tertiary":   $tertiary,
  "quaternary": $quaternary,
  "quinary":    $quinary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "gray":       $gray-600,
  "light":      $light,
  "dark":       $dark,
  "black":      $black,
  "white":      $white
), $theme-colors);

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio:   2.9;

// Customize the light and dark text colors for use in our color contrast function.
$color-contrast-dark:      $gray-800;
$color-contrast-light:     $white !default;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret:                true !default;
$enable-rounded:              true;
$enable-shadows:              false !default;
$enable-gradients:            false !default;
$enable-transitions:          true !default;
$enable-reduced-motion:       true !default;
$enable-smooth-scroll:        true !default;
$enable-grid-classes:         true !default;
$enable-cssgrid:              false !default;
$enable-button-pointers:      true !default;
$enable-rfs:                  true !default;
$enable-validation-icons:     true !default;
$enable-negative-margins:     false !default;
$enable-deprecation-messages: true !default;
$enable-important-utilities:  true !default;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

// scss-docs-start spacer-variables-maps
$spacer: 1.75rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.642857,
  5: $spacer * 2.142857,
  6: $spacer * 3,
  7: $spacer * 4,
);

$negative-spacers: if($enable-negative-margins, negativify-map($spacers), null) !default;
// scss-docs-end spacer-variables-maps

// Body
//
// Settings for the `<body>` element.

$body-bg:                   $white !default;
$body-color:                $gray-800;
$body-text-align:           null !default;

// Links
//
// Style anchor elements.

$link-color:                inherit;
$link-decoration:           none !default;
$link-hover-color:          inherit;
$link-hover-decoration:     underline !default;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

// scss-docs-start grid-breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 900px,
  xl: 1280px,
  xxl: 1400px
);
// scss-docs-end grid-breakpoints

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

// scss-docs-start container-max-widths
$container-max-widths: (
  sm: 100%,
  md: 100%,
  lg: 100%,
  xl: 1280px
);
// scss-docs-end container-max-widths


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12 !default;
$grid-gutter-width:           2rem;
$grid-row-columns:            6 !default;

$gutters: (
  0: 0,
  1: $grid-gutter-width * .5,
  2: $grid-gutter-width * .75,
  3: $grid-gutter-width * 1,
  4: $grid-gutter-width * 1.5,
  5: $grid-gutter-width * 2,
  6: $grid-gutter-width * 2.5,
);

// Container padding

$container-padding-x: $grid-gutter-width * .5 !default;

// Components
//
// Define common padding and border radius sizes and more.

// scss-docs-start border-variables
$border-width:                1px !default;
$border-widths: (
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px,
  5: 5px
) !default;

$border-color:                $gray-300 !default;
// scss-docs-end border-variables

// scss-docs-start border-radius-variables
$border-radius:               0;
$border-radius-sm:            .2rem !default;
$border-radius-lg:            .3rem !default;
$border-radius-pill:          50rem !default;
// scss-docs-end border-radius-variables

// scss-docs-start box-shadow-variables
$box-shadow:                  0 .5rem 1rem rgba($black, .15) !default;
$box-shadow-sm:               0 .125rem .25rem rgba($black, .075) !default;
$box-shadow-lg:               0 1rem 3rem rgba($black, .175) !default;
$box-shadow-inset:            inset 0 1px 2px rgba($black, .075) !default;
// scss-docs-end box-shadow-variables

$component-active-color:      $white !default;
$component-active-bg:         $gray-300;

// scss-docs-start caret-variables
$caret-width:                 .3em !default;
$caret-vertical-align:        $caret-width * .85 !default;
$caret-spacing:               $caret-width * .85 !default;
// scss-docs-end caret-variables

$transition-base:             all .2s ease-in-out !default;
$transition-fade:             opacity .15s linear !default;
// scss-docs-start collapse-transition
$transition-collapse:         height .35s ease !default;
$transition-collapse-width:   width .35s ease !default;
// scss-docs-end collapse-transition

// stylelint-disable function-disallowed-list
// scss-docs-start aspect-ratios
$aspect-ratios: (
  "1x1": 100%,
  "4x3": calc(3 / 4 * 100%),
  "16x9": calc(9 / 16 * 100%),
  "21x9": calc(9 / 21 * 100%)
) !default;
// scss-docs-end aspect-ratios
// stylelint-enable function-disallowed-list

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// scss-docs-start font-variables
// stylelint-disable value-keyword-case
// $font-family-sans-serif:      system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-sans-serif:      -apple-system, Roboto, 'Helvetica Neue', "游ゴシック体", "Yu Gothic", YuGothic, '游ゴシック Medium', 'Hiragino Kaku Gothic ProN', 'メイリオ', meiryo, sans-serif;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
// stylelint-enable value-keyword-case

// $font-size-root affects the value of `rem`, which is used for as well font sizes, paddings, and margins
// $font-size-base affects the font size of the body text
$font-size-root:              null !default;
$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$font-size-xxs:               $font-size-base * .625;
$font-size-xs:                $font-size-base * .75 !default;
$font-size-sm:                $font-size-base * .875 !default; // 14px
$font-size-lg:                $font-size-base * 1.125;

$font-weight-lighter:         lighter !default;
$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-medium:          500 !default;
$font-weight-semi-bold:       600;
$font-weight-bold:            700 !default;
$font-weight-bolder:          bolder !default;

$font-weight-base:            $font-weight-normal !default;

$line-height-base:            1.5 !default;
$line-height-sm:              1.25 !default;
$line-height-lg:              2 !default;
$line-height-xl:              2.3;

$h1-font-size:                $font-size-base * 2.5 !default;
$h2-font-size:                $font-size-base * 2 !default;
$h3-font-size:                $font-size-base * 1.75 !default;
$h4-font-size:                $font-size-base * 1.5 !default;
$h5-font-size:                $font-size-base * 1.25 !default;
$h6-font-size:                $font-size-base !default;
// scss-docs-end font-variables

// scss-docs-start font-sizes
$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size
) !default;
// scss-docs-end font-sizes

// scss-docs-start headings-variables
$headings-margin-bottom:      $spacer * .5 !default;
$headings-font-family:        null !default;
$headings-font-style:         null !default;
$headings-font-weight:        500 !default;
$headings-line-height:        1.2 !default;
$headings-color:              null !default;
// scss-docs-end headings-variables

// scss-docs-start display-headings
$display-font-sizes: (
  1: 5rem,
  2: 4.5rem,
  3: 4rem,
  4: 3.5rem,
  5: 3rem,
  6: 2.5rem
) !default;

$display-font-weight: 300 !default;
$display-line-height: $headings-line-height !default;
// scss-docs-end display-headings

// scss-docs-start type-variables
$lead-font-size:              $font-size-base * 1.25 !default;
$lead-font-weight:            300 !default;

$small-font-size:             .875em !default;

$sub-sup-font-size:           .75em !default;

$text-muted:                  $gray-600 !default;

$initialism-font-size:        $small-font-size !default;

$blockquote-margin-y:         $spacer !default;
$blockquote-font-size:        $font-size-base * 1.25 !default;
$blockquote-footer-color:     $gray-600 !default;
$blockquote-footer-font-size: $small-font-size !default;

$hr-margin-y:                 $spacer !default;
$hr-color:                    inherit !default;
$hr-height:                   $border-width !default;
$hr-opacity:                  .25 !default;

$legend-margin-bottom:        .5rem !default;
$legend-font-size:            1.5rem !default;
$legend-font-weight:          null !default;

$mark-padding:                .2em !default;

$dt-font-weight:              $font-weight-bold !default;

$nested-kbd-font-weight:      $font-weight-bold !default;

$list-inline-padding:         .5rem !default;

$mark-bg:                     #fcf8e3 !default;
// scss-docs-end type-variables

// Breadcrumbs

// scss-docs-start breadcrumb-variables
$breadcrumb-font-size:              null !default;
$breadcrumb-padding-y:              0 !default;
$breadcrumb-padding-x:              0 !default;
$breadcrumb-item-padding-x:         1em;
$breadcrumb-margin-bottom:          1rem !default;
$breadcrumb-bg:                     null !default;
$breadcrumb-divider-color:          $gray-600 !default;
$breadcrumb-active-color:           $gray-600 !default;
$breadcrumb-divider:                quote("/") !default;
$breadcrumb-divider-flipped:        $breadcrumb-divider !default;
$breadcrumb-border-radius:          null !default;
// scss-docs-end breadcrumb-variables


// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

// scss-docs-start input-btn-variables
$input-btn-padding-y:         0.7em;
$input-btn-padding-x:         .75rem !default;
$input-btn-font-family:       null !default;
$input-btn-font-size:         $font-size-base !default;
$input-btn-line-height:       $line-height-base !default;

$input-btn-focus-width:         .2rem;
$input-btn-focus-color-opacity: .25 !default;
$input-btn-focus-color:         rgba($component-active-bg, $input-btn-focus-color-opacity) !default;
$input-btn-focus-blur:          0 !default;
$input-btn-focus-box-shadow:    0 0 $input-btn-focus-blur $input-btn-focus-width $input-btn-focus-color !default;

$input-btn-padding-y-sm:      .25rem !default;
$input-btn-padding-x-sm:      .5rem !default;
$input-btn-font-size-sm:      $font-size-sm !default;

$input-btn-padding-y-lg:      .5rem !default;
$input-btn-padding-x-lg:      1rem !default;
$input-btn-font-size-lg:      $font-size-lg !default;

$input-btn-border-width:      $border-width !default;
// scss-docs-end input-btn-variables

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

// scss-docs-start btn-variables
$btn-padding-y:               $input-btn-padding-y !default;
$btn-padding-x:               $input-btn-padding-x !default;
$btn-font-family:             $input-btn-font-family !default;
$btn-font-size:               $input-btn-font-size !default;
$btn-line-height:             $input-btn-line-height !default;
$btn-white-space:             null !default; // Set to `nowrap` to prevent text wrapping

$btn-padding-y-sm:            $input-btn-padding-y-sm !default;
$btn-padding-x-sm:            $input-btn-padding-x-sm !default;
$btn-font-size-sm:            $input-btn-font-size-sm !default;

$btn-padding-y-lg:            $input-btn-padding-y-lg !default;
$btn-padding-x-lg:            $input-btn-padding-x-lg !default;
$btn-font-size-lg:            $input-btn-font-size-lg !default;

$btn-border-width:            $input-btn-border-width !default;

$btn-font-weight:             $font-weight-bold;
$btn-box-shadow:              inset 0 1px 0 rgba($white, .15), 0 1px 1px rgba($black, .075) !default;
$btn-focus-width:             $input-btn-focus-width !default;
$btn-focus-box-shadow:        $input-btn-focus-box-shadow !default;
$btn-disabled-opacity:        .65 !default;
$btn-active-box-shadow:       inset 0 3px 5px rgba($black, .125) !default;

$btn-link-color:              $link-color !default;
$btn-link-hover-color:        $link-hover-color !default;
$btn-link-disabled-color:     $gray-600 !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius:           $border-radius !default;
$btn-border-radius-sm:        $border-radius-sm !default;
$btn-border-radius-lg:        $border-radius-lg !default;

$btn-transition:              color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;

$btn-hover-bg-shade-amount:       15% !default;
$btn-hover-bg-tint-amount:        15% !default;
$btn-hover-border-shade-amount:   20% !default;
$btn-hover-border-tint-amount:    10% !default;
$btn-active-bg-shade-amount:      20% !default;
$btn-active-bg-tint-amount:       20% !default;
$btn-active-border-shade-amount:  25% !default;
$btn-active-border-tint-amount:   10% !default;
// scss-docs-end btn-variables

// scss-docs-start form-input-variables
$input-padding-y:                       0.9em;
$input-padding-x:                       0.9em;
$input-font-family:                     $input-btn-font-family !default;
$input-font-size:                       $input-btn-font-size !default;
$input-font-weight:                     $font-weight-base !default;
$input-line-height:                     $input-btn-line-height !default;

$input-padding-y-sm:                    $input-btn-padding-y-sm !default;
$input-padding-x-sm:                    $input-btn-padding-x-sm !default;
$input-font-size-sm:                    $input-btn-font-size-sm !default;

$input-padding-y-lg:                    $input-btn-padding-y-lg !default;
$input-padding-x-lg:                    $input-btn-padding-x-lg !default;
$input-font-size-lg:                    $input-btn-font-size-lg !default;

$input-bg:                              $gray-200;
$input-disabled-bg:                     $gray-200 !default;
$input-disabled-border-color:           null !default;

$input-color:                           $body-color !default;
$input-border-color:                    $gray-400 !default;
$input-border-width:                    0;
$input-box-shadow:                      $box-shadow-inset !default;

$input-border-radius:                   $border-radius !default;
$input-border-radius-sm:                $border-radius-sm !default;
$input-border-radius-lg:                $border-radius-lg !default;

$input-focus-bg:                        $input-bg !default;
$input-focus-border-color:              tint-color($component-active-bg, 50%) !default;
$input-focus-color:                     $input-color !default;
$input-focus-width:                     $input-btn-focus-width !default;
$input-focus-box-shadow:                $input-btn-focus-box-shadow !default;

$input-placeholder-color:               $gray-600 !default;
$input-plaintext-color:                 $body-color !default;

$input-height-border:                   $input-border-width * 2 !default;

$input-height-inner:                    add($input-line-height * 1em, $input-padding-y * 2) !default;
$input-height-inner-half:               add($input-line-height * .5em, $input-padding-y) !default;
$input-height-inner-quarter:            add($input-line-height * .25em, $input-padding-y * .5) !default;

$input-height:                          add($input-line-height * 1em, add($input-padding-y * 2, $input-height-border, false)) !default;
$input-height-sm:                       add($input-line-height * 1em, add($input-padding-y-sm * 2, $input-height-border, false)) !default;
$input-height-lg:                       add($input-line-height * 1em, add($input-padding-y-lg * 2, $input-height-border, false)) !default;

$input-transition:                      border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;

$form-color-width:                      3rem !default;
// scss-docs-end form-input-variables