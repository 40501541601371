.is--about {
  .rwd002-breadcrumbs {
    display: none;
  }
}

.about {
  &__lead {
    position: relative;
    text-align: center;
    padding: map-get($spacers, 6) map-get($spacers, 3);
    font-size: min(4.8vw, 28 / 16 * $font-size-base);
    line-height: 1.83;
    letter-spacing: 0.16em;
    font-feature-settings: "palt";
    color: $primary;
    font-weight: $font-weight-bold;
    @include media-down(lg) {
      padding-block: map-get($spacers, 3);
    }
    p:last-child {
      margin-bottom: 0;
    }
    &:before {
      content: '';
      position: absolute;
      left: 50%;
      top: -0.5em;
      transform: translateX(-50%);
      background: svgUrlFunc('<svg enable-background="new 0 0 12 88" viewBox="0 0 12 88" xmlns="http://www.w3.org/2000/svg"><g fill="none" stroke="#d5d5d5" stroke-miterlimit="10" stroke-width="2"><path d="m5.64157.00284.21843 77"/><path d="m10.72 83.76146v-5.61185l-4.86-2.80591-4.86 2.80591v5.61185l4.86 2.80592z"/></g></svg>') center / contain no-repeat;
      height: 88px;
      width: 12px;
      @include media-down(lg) {
        top: -12px;
        width: 6px;
        height: 38px;
      }
    }
  }

  &__features {
    display: grid;
    position: relative;
    z-index: 0;
    @include media-up(lg) {
      row-gap: map-get($spacers, 5);
      padding-bottom: map-get($spacers, 7);
    }
    &:after {
      position: absolute;
      content: '';
      top: 258px;
      left: 0;
      bottom: 0;
      width: 100%;
      background: url('/assets/img/webp/concrete@2x.webp') center / cover no-repeat;
      z-index: -1;
    }
  }

}

.about-feature {
  display: flex;
  @include media-up(lg) {
    justify-content: flex-end;
    height: 516px;
  }
  @include media-down(lg) {
    flex-direction: column-reverse;
  }
  &:nth-child(odd){
    @include media-up(lg) {
      flex-direction: row-reverse;
      margin-right: map-get($spacers, 3);
    }
  }
  &:nth-child(odd) &__img {
    @include media-up(lg) {
      margin-right: -67px;
    }
  }
  &:nth-child(even) {
    @include media-up(lg) {
      margin-left: map-get($spacers, 3);
    }
  }
  &:nth-child(even) &__img {
    @include media-up(lg) {
      margin-left: -67px;
    }
  }

  &__body {
    position: relative;
    z-index: 1;
    color: $white;
    @include media-up(lg) {
      max-width: 752px;
      padding: map-get($spacers, 3) min(9.4vw, 131px);
    }
    @include media-down(lg) {
      padding: map-get($spacers, 4) map-get($spacers, 3);
    }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      background-color: $primary;
      z-index: -1;
      @include media-up(lg) {
        clip-path: polygon(67px 0, calc(100% - 67px) 0, 100% 50%, calc(100% - 67px) 100%, 67px 100%, 0 50%);
      }
      @include media-down(lg) {
        top: -50px;
        clip-path: polygon(0 50px, 50% 0, 100% 50px, 100% 100%, 0 100%);
      }
    }
    .title {
      color: $secondary;
      font-family: 'Montserrat', $font-family-base;
      font-size: min(10.1vw, 61 / 16 * $font-size-base);
      font-weight: $font-weight-bold;
      letter-spacing: 0.05em;
      margin-bottom: 0.4em;
      @include media-up(lg) {
        margin-inline: max(-9.4vw, -131px);
      }
      @include media-down(lg) {
        text-align: center;
      }
    }
    .lead {
      font-size: min(5.33vw, 28 / 16 * $font-size-base);
      font-weight: $font-weight-bold;
      line-height: 1.75;
      letter-spacing: 0.16em;
      font-feature-settings: "palt";
      @include media-down(lg) {
        text-align: center;
      }
    }
    p {
      line-height: 2;
      letter-spacing: 0.14em;
      margin-right: -0.15em;
    }
  }

  &:nth-child(odd) &__body .title {
    @include media-up(lg) {
      text-align: right;
    }
  }
  &__img {
    @include media-up(lg) {
      width: calc(50vw - 67px);
    }
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      max-width: 100%;
    }
  }
}

.about-silikali {
  display: grid;
  justify-content: center;
  padding: 0 map-get($spacers, 3);
  gap: 1em map-get($spacers, 5);
  @include media-up(lg) {
    grid-auto-flow: column;
    margin-top: map-get($spacers, 5);
  }
  @include media-down(lg) {
    padding: map-get($spacers, 5) map-get($spacers, 3);
  }

  .name {
    display: block;
    max-width: 100%;
    height: auto;
    margin-bottom: map-get($spacers, 3);
  }
  &__body {
    order: 2;
    line-height: 2;
    letter-spacing: 0.12em;
    @include media-up(lg) {
      max-width: 498px;
    }
  }
  &__img {
    order: 1;
    display: flex;
    justify-content: center;
    img {
      max-width: 100%;
      height: auto;
      display: block;
      @include media-down(lg) {
        max-width: 60vw;
      }
    }
  }

  &__btnwrap {
    margin-top: 1.5em;
    .btn {
      width: 100%;
      max-width: 240px;
      letter-spacing: 0.12em;
      font-size: calc(14 / 16 * $font-size-base);
    }
    @include media-down(lg) {
      display: flex;
      justify-content: center;
    }
  }
}

.about-vision {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $primary;
  padding: map-get($spacers, 6) map-get($spacers, 3);
  background: url('/assets/img/webp/about/vision_haikei@2x.webp') center 75% / cover no-repeat;
  @include media-down(lg) {
    background-image: url('/assets/img/webp/about/vision_haikei_sp@2x.webp');
  }

  &__title {
    text-align: center;
    font-family: 'Montserrat', $font-family-base;
    font-size: min(10.1vw, 61 / 16 * $font-size-base);
    font-weight: $font-weight-bold;
    letter-spacing: 0.05em;
  }
  &__lead {
    text-align: center;
    font-size: min(5.33vw, 32 / 16 * $font-size-base);
    font-weight: $font-weight-bold;
    line-height: 1.75;
    letter-spacing: 0.16em;
    font-feature-settings: "palt";
  }
  p {
    line-height: 2;
    letter-spacing: 0.12em;
    @include media-up(lg) {
      max-width: 624px;
    }
  }
  &__btnwrap {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 1.5em;
    .btn {
      width: 100%;
      max-width: 240px;
      letter-spacing: 0.12em;
      font-size: calc(14 / 16 * $font-size-base);
    }
  }
}