.rwd002-breadcrumbs {
  position: absolute;
  width: 100%;
  display: flex;
  padding-left: map-get($spacers, 3);
  padding-right: map-get($spacers, 3);
  transform: translateY(-100%);
  // margin-top: map-get($spacers, 6);
  // margin-bottom: map-get($spacers, 4);

  &__list {
    @extend .breadcrumb;
    width: 100%;
    max-width: 1400px;
    padding-bottom: 1em;
    margin: auto;
    justify-content: flex-end;
    border-bottom: 1px solid #E7EBF0;
  }
  &__item {
    @extend .breadcrumb-item;
    @extend .text-truncate;
    padding-left: 0;
    margin-right: 0.5em;
    max-width: 33%;
    letter-spacing: 0.14em;
    font-feature-settings: "palt";
    font-size: calc(10 / 16 * $font-size-base);
    font-weight: $font-weight-bold;
    letter-spacing: 0.2em;
    color: tint($primary, 50%);
    @include media-down(lg) {
      display: none;
    }
    & + &{
      padding-left: 0;
    }
    & + &::before {
      content: '';
      display: inline-block;
      height: 1px;
      width: 3em;
      background-color: $gray-300;
      margin-top: 0.7em;
      margin-right: 0.5em;
    }
    &:last-child {
      color: $primary;
    }
  }
}
