.is--company {
  .rwd002-breadcrumbs {
    &__list {
      border-bottom-width: 0;
    }
    &__item,
    &__item:last-child {
      color: $white;
    }
  }
}

.company-nav {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: min(5.3vw, 3em);
  row-gap: 1em;
  margin-block: map-get($spacers, 4);
  .btn-link{
    padding: 0.5em 0;
    border-bottom: solid currentColor;
    border-width: 0 0 2px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: min(50% - 2.65vw ,155px);
    color: $primary;
    font-size: min(3.46vw, 15 / 16 * $font-size-base);
    font-weight: $font-weight-bold;
    letter-spacing: 0.25em; 
    &:hover,&:focus{
      text-decoration: none;
      color: tint($primary, 10%);
    }
  }
}

.company-message {
  margin-block: map-get($spacers, 7);
  &__title {
    @include c-section-title($teal, $primary);
  }

  &__container {
    display: flex;
    column-gap: map-get($spacers, 5);
    row-gap: map-get($spacers, 3);
    max-width: 1400px;
    margin-inline: auto;
    justify-content: flex-start;
    @include media-down(lg) {
      flex-direction: column;
      padding-inline: map-get($spacers, 3);
    }
  }
  &__body {
    order: 2;
    line-height: 1.875;
    letter-spacing: 0.12em;
    @include media-up(lg) {
      max-width: min(60vw, 730px + map-get($spacers, 3));
      padding-right: map-get($spacers, 4);
    }
    p + p {
      margin-top: 2em;
      @include media-down(lg) {
        margin-top: 1em;
      }
    }
    .signature {
      display: flex;
      margin-left: auto;
      flex-direction: column;
      align-items: flex-start;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__signature {
    display: flex;
    justify-content: flex-end;
    font-size: calc(22 / 16 * $font-size-base);
    letter-spacing: 0.2em;
    small {
      font-size: 0.55em;
      letter-spacing: 0.2em;
      line-height: 1.5;
    }
  }
  &__img {
    display: flex;
    justify-content: center;
    order: 1;
    img {
      display: block;
      height: auto;
      max-width: 100%;
    }
  }
}

.company-outline {
  margin-block: map-get($spacers, 7);
  @include media-down(lg) {
    margin-block: map-get($spacers, 6) map-get($spacers, 7);
  }
  &__title {
    @include c-section-title($teal, $primary);
  }
  &__container {
    display: flex;
    justify-content: center;
    padding-inline: map-get($spacers, 3);
  }

  &__table {
    @include table-typeA;
    max-width: 750px;
  }
}