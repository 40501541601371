@mixin table-typeA {
  width: 100%;
  th {
    position: relative;
  }
  th:after {
    position: absolute;
    bottom: 100%;
    left: 0;
    content: '';
    width: 2em;
    height: 2px;
    background-color: #C2C3C3;
  }
  tr:first-child th:after {
    display: none;
  }
  th,td {
    border: solid #E8E8E8;
    border-width: 0 0 2px;
    padding: 1em;
    letter-spacing: 0.15em;
    font-size: calc(15 / 16 * $font-size-base);
    line-height: 1.8;
    font-weight: $font-weight-normal;
    @include media-down(lg) {
      display: block;
    }
  }
  tr:last-child th,
  tr:last-child td {
    border-bottom-width: 0;
  }
  th {
    padding-left: 0;
    color: #879398;
    letter-spacing: 0.34em;
    @include media-down(lg) {
      border-bottom-width: 0;
      padding-bottom: 0.4em;
    }
  }
  td {
    @include media-down(lg) {
      padding-left: 0;
      padding-top: 0;
      padding-right: 0;
    }
  }
  ul {
    padding-left: 1em;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
